import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import { Link } from "gatsby"
import * as aboutUsStyles from "./about-us.module.scss"

const aboutUs = ({ pageContext, location }) => {
  const pageTitle = "About Us"

  return (
    <Layout>
      <Seo title={pageTitle} />
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />
      <div className={`inner-y-padding ${aboutUsStyles.wrapper}`}>
        <div className="inner-container-alt">
          <nav className={` ${aboutUsStyles.links}`} aria-label="in-page">
            <h2 className="h3">Find out more</h2>
            <ul>
              <li>
                <Link to="./history" className="biggerLink" title="History">
                  History
                </Link>
              </li>
              <li>
                <Link to="./values" className="biggerLink" title="values">
                  Values
                </Link>
              </li>
              <li>
                <Link to="./strategy" className="biggerLink" title="Strategy">
                  Strategy
                </Link>
              </li>
              <li>
                <Link
                  to="./CSR-sustainability"
                  className="biggerLink"
                  title="CSR Sustainability"
                >
                  CSR Sustainability
                </Link>
              </li>
              <li>
                <Link to="./rcif" className="biggerLink" title="RCIF">
                  RCIF
                </Link>
              </li>
              <li>
                <Link
                  to="./contact-us"
                  className="biggerLink"
                  title="Contact Us"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </Layout>
  )
}

export default aboutUs
